<template>
  <a-modal :visible.sync="show"
           title="添加商品"
           destroyOnClose
           width="800px"
           @ok="handleOk"
           @cancel="$emit('update:show', false)">
    <div class="modal-search-bar">
      <a-form-model layout="horizontal"
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    ref="searchRef"
                    :model="searchForm">
        <a-row>
          <!-- <a-col :span="5">
            <a-form-model-item prop="type" label=""
                               :wrapper-col="{span: 24}">
              <a-select v-model="searchForm.goods_type"
                        placeholder="商品分类"
                        style="width:100%;">
                <a-select-option value>全部分类</a-select-option>
                <a-select-option v-for="item in Object.keys(goodsType)"
                                 :value="item"
                                 :key="item">{{goodsType[item]}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
          <!-- <a-col :span="12">
            <a-form-model-item prop="goods_number"
                               label="商品编码">
              <a-input allowClear
                       v-model="searchForm.goods_number"
                       placeholder="商品编码"></a-input>
            </a-form-model-item>
          </a-col> -->

          <a-col :span="10">
            <a-form-model-item prop="goods_name"
                               label="商品名称">
              <a-input allowClear
                       v-model="searchForm.goods_name"
                       placeholder="商品名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="5">
            <a-form-model-item :wrapper-col="{span: 24}">
              <a-checkbox class="mr-3"
                        @change="searchOnSaleChange"
                        :checked="searchForm.look_on_sale==1"
                      >只看上架</a-checkbox>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item :labelCol="{span:0}">
              <a-button @click="handlerSearch"
                        type="primary">搜索</a-button>
              <!-- <a-button style="margin-left: 10px"
                        @click="resetForm">重置</a-button> -->
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <base-table ref="goodsTable"
                :columnsData="columns"
                rowKey="sku_id"
                :row-selection="rowSelection"
                :tableData="tableData"
                :customRow="Rowclick"
                :total="total"
                :getListFunc="initData"
                :page.sync="searchForm.page"
                :customHeight="300"
                :pageSize.sync="searchForm.page_count">
                <div slot="footer">
        已选
        <span class="text-red-500">{{ rows.length }}</span>项
      </div></base-table>
  </a-modal>
</template>

<script>
// import { formatGoodsType, goodsType } from "@/utils/type"
import { getGoodsListByOutletsId } from "@/api/outlets"
import _ from 'lodash'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectRows: {
      type: Array,
      default: () => [],
    },

    outletsId:{
      type: [String,Number],
      default: ''
    }
  },
  data () {
    return {
      // goodsType: goodsType,
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },
      searchForm: {
        look_on_sale: 1,
        goods_number: "",
        goods_name: "",
        goods_type: "",
        outlets_id: this.outletsId || '',
        page: 1,
        page_count: 30,
      },
      columns: [
        // {
        //   title: "ID",
        //   dataIndex: "sku_id",
        //   align: "center",
        // },
        // {
        //   title: "商品分类",
        //   dataIndex: "goods_type",
        //   align: "center",
        //   width: 80,
        //   slots: {
        //     customRender: "type",
        //   },
        //   slotsType: "format",
        //   slotsFunc: (val) => formatGoodsType(val),
        // },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
          // width: 120,
        },
        {
          title: "口味",
          dataIndex: "flavor_name",
          align: "center",
          // width: 70,
        },
        {
          title: "尺寸",
          dataIndex: "specification_name",
          align: "center",
          // width: 70,
        },
        {
          title: "售价",
          dataIndex: "price",
          align: "center",
          width: 80,
        },
        {
          title: "库存",
          dataIndex: "stock",
          align: "center",
          width: 80,
          slots: {
            customRender: "stock",
          },
          slotsType: "format",
          slotsFunc: (val) => {return val==-1?"未上架":(val>999?999:val)},
        },
      ],
      tableData: [],
      total: 0,
      rows: this.selectRows,
      rowSelection: {
        selectedRowKeys: [],
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection.selectedRowKeys.unshift(record.sku_id)
            this.rows.push(record)
          } else {
            this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
              (el) => el != record.sku_id
            )
            this.rows = this.rows.filter((el) => el.sku_id != record.sku_id)
          }
        },
        onSelectAll: (selected, selectedRows) => {
          this.selectRows = this.selectedRows
          if (selected) {
            this.rowSelection.selectedRowKeys = selectedRows.map(
              (el) => el.sku_id
            )
            this.rows = selectedRows
          } else {
            this.rowSelection.selectedRowKeys = []
            this.rows = []
          }
        },
        getCheckboxProps: (record) => {
          return {
            props: {
              defaultChecked: this.rowSelection.selectedRowKeys.includes(record.sku_id)
            }
          };
        }
      },
    }
  },
  mounted () {
    this.initData()
    this.rows = _.cloneDeep(this.selectRows)
    this.rowSelection.selectedRowKeys = this.selectRows.map(el=>el.sku_id)
  },
  methods: {
    async initData () {
      const { data, code } = await getGoodsListByOutletsId(this.searchForm)
      if (code === 0) {
        const datas = data.list
        let skuArr = []
        datas.map((goods) => {
          goods.sku_data.map((item) => {
            let obj = goods
            obj.flavor_name = item.flavor_name

            if (item.specification_list.length > 0) {
              item.specification_list.map((el) => {
                obj = { ...obj, ...el }
                delete obj.sku_data
                skuArr.push(obj)
              })
            }
          })
        })
        this.tableData = skuArr

        this.total = data.total_count
      }
    },
    // 保存
    handleOk () {
      let _this = this
      let flag = false
      this.rows.map(el=>{
        if(el.stock === -1){
          flag = true
        }
      })
      if (flag) {
        this.$confirm({
          title: "温馨提示",
          content: '部分商品无库存，确认添加？',
          okText: "确认",
          cancelText: "取消",
          onOk() {
            _this.$emit("ok", _this.rows)
            _this.$emit("update:show", false)
          },
        })
      }else{
        this.$emit("update:show", false)
        this.$emit("ok", this.rows)
      }
      
    },
    handlerSearch () {
      this.searchForm.page = 1
      this.initData()
    },
    resetForm () {
      this.searchForm.goods_number = ""
      this.searchForm.goods_name = ""
      this.searchForm.goods_type = ""
      this.searchForm.page = 1
      this.searchForm.page_count = 30
      this.$refs.searchRef.resetFields()
      this.initData()
    },
    searchOnSaleChange(){
      if(this.searchForm.look_on_sale == 1){
        this.searchForm.look_on_sale = ""
      }else{
        this.searchForm.look_on_sale = 1
      }
      this.handlerSearch()
    },
    Rowclick (record) {
      return {
        on: {
          click: () => {
            //如果是已选就去掉
            if (this.rowSelection.selectedRowKeys.includes(record.sku_id)) {
              this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
                (el) => el != record.sku_id
              )
              this.rows = this.rows.filter(
                (el) => el.sku_id != record.sku_id
              )
            } else {
              this.rowSelection.selectedRowKeys.unshift(record.sku_id)
              this.rows.push(record)
            }
          },
        },
      }
    },
  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>