import { formatGoodsType, goodsType, candleOptions } from '@/utils/type'

export const rowKey = "id";


export const detail_rowKey = "goods_number";

export const detail_columns = [
  {
    title: "商品分类",
    dataIndex: "goods_type",
    align: "center",
    width: 80,
    slots: {
      customRender: "type",
    },
    slotsType: "format",
    slotsFunc: (val) => formatGoodsType(val),
  },
  {
    title: "商品名称",
    dataIndex: "goods_name",
    align: "center",
    width: "40%",
  },
  {
    title: "口味",
    dataIndex: "flavor_name",
    align: "center",
    width: "40%",
  },
  {
    title: "尺寸",
    dataIndex: "specification_name",
    align: "center",
    width: "40%",
  },
  {
    title: "数量",
    dataIndex: "number",
    align: "center",
    width: 80,
  },
  // {
  //   title: "总价",
  //   dataIndex: "goods_price",
  //   align: "center",
  //   width: 80,
  // },
  {
    title: "商品状态",
    dataIndex: "status",
    align: "center",
    width: 100,
    slots: {
      customRender: "status",
    },
  },
];


/********************  新增页面列表   *********************************************** */
export const add_columns = [// 门店列表
  // {
  //   title: "SKU码",
  //   dataIndex: "sku_id",
  //   align: "center",
  // },
  // {
  //   title: "商品分类",
  //   dataIndex: "goods_type",
  //   align: "center",
  //   slots: {
  //     customRender: "type",
  //   },
  //   slotsType: "format",
  //   slotsFunc: (val) => formatGoodsType(val),
  // },
  {
    title: "商品名称",
    dataIndex: "goods_name",
    align: "center",
  },
  {
    title: "口味",
    dataIndex: "flavor_name",
    align: "center",
  },
  {
    title: "尺寸",
    dataIndex: "specification_name",
    align: "center",
  },
  {
    title: "数量",
    dataIndex: "number",
    align: "center",
    slots: { customRender: "number" },
  },
  {
    title: "商品价格",
    dataIndex: "price",
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "status",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: 140,
    slots: { customRender: "operation" },
  }]


export {
  candleOptions
}

export default {
  detail_rowKey,
  detail_columns,
  rowKey,
  goodsType,
  candleOptions,

  add_columns
};
