<template>
  <a-modal
    :visible.sync="show"
    title="选择门店"
    destroyOnClose
    width="800px"
    @cancel="$emit('update:show', false)"
    :footer="null"
  >
    <!-- <div class="modal-search-bar mb-2" v-if="formData.order_type == 1"> -->
    <div class="modal-search-bar flex mb-2">
      <!-- <a-select class="mr-2"
        ref="selectCity"
        v-model="searchForm.adcode"
        style="width:100px;"
        placeholder="城市"
      >
        <a-select-option
          v-for="(item, index) in cityList"
          :key="index"
          :label="item.name"
          :value="item.adcode"
        >{{ item.name }}</a-select-option>
      </a-select> -->
      <a-input allowClear placeholder="网点名/关键词" class="mr-2" style="width:220px;"
            v-model="search_name" @keyup.enter="handlerSearch"></a-input>
      <a-select
        allowClear
        show-search
        v-model="address"
        placeholder="输入地址可以搜索附近门店"
        style="flex:1;"
        :default-active-first-option="false"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        @search="handleSearchAddress"
        @change="handleChangeAddress"
      >
        <a-select-option v-for="d in addressList" :key="d.id">{{ d.title }}<span class="addr-region">{{ d.region }}</span></a-select-option>
      </a-select>
      <a-button class="ml-4" @click="handlerReset">重置</a-button>
      <a-button class="ml-4" @click="handlerSearch" type="primary">搜索</a-button>
    </div>
    <base-table
      ref="goodsTable"
      :customHeight="400"
      :columnsData="columns"
      rowKey="outlets_id"
      :rowClassName="getRowClass"
      :tableData="tableData"
    >
      <template #operation="{ record }">
        <a-button type="link" @click="handlerSelect(record)">选择</a-button>
      </template>
    </base-table>
  </a-modal>
</template>

<script>
// import { getCityList } from "@/api/outlets"
import { getPickUpOutletsList } from "@/api/order/create"
import { getMapPointList, getDistance } from "@/utils/tMap.js"
// import { checkAdcodes } from "@/utils/address.js"
import {
  // isShopOutlets,
  getOutletsInfo,
} from "@/utils/authData.js"

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: [String, Number],
    },
    cityAdcode: {
      type: [String, Number],
    },
    formData: {
      type: Object,
      default: () => { }
    },
    selectKeys: {
      type: Array,
    },
    handlerOk: {
      type: Function,
    },
    initList: {
      type: Function,
    },
  },
  data() {
    return {
      searchForm: {
        adcode: undefined,
      },
      search_name: undefined,
      address: undefined,
      addressInfo: {},
      columns: [
        {
          title: "门店名",
          dataIndex: "outlets_name",
          align: "left",
          width: 100,
        },
        {
          title: "联系电话",
          dataIndex: "tel",
          align: "left",
          width: 130,
        },
        {
          title: "地址",
          dataIndex: "address",
          align: "left",
          // width: "45%",
        },
        {
          title: "距离",
          dataIndex: "distance",
          align: "center",
          width: 80,
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => (val?val+'km':""),
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          width: 70,
          slots: {
            customRender: "operation",
          },
        },
      ],
      // cityList: [],
      tableData: [],
      baseList: [],
      addressList: [],
      total: 0,
      
      myShopId: "",
    }
  },
  mounted() {
    let outletsInfo = getOutletsInfo()
    this.myShopId = outletsInfo && outletsInfo.outlets_id

    this.init()
  },
  methods: {
    async init() {
      console.log("init formData", this.formData, this.cityAdcode)
      
      this.searchForm.adcode = this.cityAdcode

      // 配送-收货地址预置
      if(this.formData && this.formData.order_type == 2 && this.formData.consignee_lat){
        this.addressInfo = {
          location: {
            lat: this.formData.consignee_lat,
            lng: this.formData.consignee_lng,
          },
          address: this.formData.consignee_address,
          adcode: this.cityAdcode
        }
      }

      // 检索门店 展示
      if(this.searchForm.adcode){
        this.initShopList()
      }
    },
    async initShopList() {
      if(!this.searchForm.adcode){
        this.$message.warning('请选择城市')
        return
      }

      let res = await getPickUpOutletsList(this.searchForm)
      if(res.code == 0){
        this.baseList = res.data.list
        // this.handlerSort()
        this.handlerSearch()
      }
    },
    handlerSearch(){
      let list = this.baseList
      if(this.search_name){
        list = list.filter(el=>{
          if(el.outlets_id == this.myShopId) return true
          return el.outlets_name.indexOf(this.search_name) > -1 || el.address.indexOf(this.search_name) > -1
        })
      }

      let arr = [];
      if(this.addressInfo && this.addressInfo.location){
        arr = list.map(item => {
          item.distance = getDistance(
            this.addressInfo.location.lat, this.addressInfo.location.lng,
            item.outlets_lat_lng.split(' ')[1], item.outlets_lat_lng.split(' ')[0]
          ).toFixed(2)
          return item
        })
        arr.sort((a,b)=>{
          if(a.outlets_id == this.myShopId) return -1
          if(b.outlets_id == this.myShopId) return 1
          return a.distance-b.distance
        })
      }else{
        arr = list
        arr.sort((a,b)=>{
          if(a.outlets_id == this.myShopId) return -1
          if(b.outlets_id == this.myShopId) return 1
          return 0
        })
      }

      this.tableData = arr
    },
    // 地址输入
    handleSearchAddress(value) {
      getMapPointList(value).then((res) => {
        if (res.status == 0) {
          this.addressList = res.data.map(item=>{
            item.region = item.province+(item.city || '')+(item.district || '')
            return item
          })
        }
      })
    },
    async handleChangeAddress(value) {
      const item = this.addressList.find((item) => item.id === value)
      this.addressInfo = item
      this.address = item.address + item.title

      this.handlerSearch()
    },

    handlerSelect(row) {
      console.log("this.searchForm.adcode", this.searchForm.adcode)

      this.formData.outlets_id = row.outlets_id
      this.formData.outlets_name = row.outlets_name
      this.formData.outlets_adcode = this.searchForm.adcode

      this.$emit("ok", row)
      this.$emit("update:show", false)
    },

    handlerReset(){
      this.search_name = undefined
      this.address = undefined
      this.addressInfo = null
      this.handlerSearch()
    },

    getRowClass(record) {
      if(this.myShopId && record.outlets_id == this.myShopId){
        return 'my-shop'
      }
      return ""
    },
  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
.addr-region{
  margin-left: 10px;
  color: #ccc;
}
.my-shop{
  background: #fff4a4;
}
</style>