// import {
//   getDeliveryList
// } from '../api/shop.js'
import { getDeliveryOutletsList } from "@/api/outlets"
import { getDeliveryArea } from "@/utils/delivery.js"

// import { getDistance } from "@/utils/tMap.js"


// 门店下单： 地址 --> 能配送的网点（含配送范围）
export async function searchShopDeliveryArea(poi, adcode) {
	console.log(adcode)
	return new Promise((resolve, reject) => {
		getDeliveryOutletsList({
			// adcode: adcode
		}).then((res) => {
			if(res.code == 0){
				let area = getDeliveryArea(res.data.list, {
					lat: poi.lat,
					lng: poi.lng
				})
				if(!area){
					console.log("没有配送站能送到你家")
				}
				resolve(area);
			}else{
				reject(res)
			}
		})
	})
}